import {graphql, Link} from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import {Button} from '../../../components/button';
import {Cta} from '../../../components/cta';
import {Layout} from '../../../components/layout';

export default ({data}: any) => (
    <Layout
        title="Initiële versie van het softwareproduct realiseren – Stap 3 van onze aanpak"
        description="Initiële versie van het softwareproduct realiseren – Stap 3 van onze aanpak."
        ogImage="productrealisatie.png"
        translatedPagePath="/en/approach/product-realisation/">
        <Link to="/nl/werkwijze/prototyping/" className="body__nav body__nav--left" title="Aanpakstap 2. Prototype">
            Vorige stap
        </Link>

        <Link to="/nl/werkwijze/service/" className="body__nav body__nav--right" title="Aanpakstap 4A. Serveren">
            Volgende stap
        </Link>

        <section className="section">
            <article className="content content--no-hero">
                <div className="container">
                    <div className="content__display">
                        <h2>Product&shy;realisatie</h2>
                        <div className="display__number">3</div>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            Voor de product&shy;realisatie gaan we verder met een schone lei. Met de kennis en plannen{' '}
                            uit de kick-off- en concept&shy;fases in handen, ont&shy;werpen en ont&shy;wikkelen we samen{' '}
                            de eerste levens&shy;vatbare versie van het software&shy;product&mdash;helemaal vanuit het{' '}
                            niets in een relatief korte periode.
                        </p>
                        <p>
                            Ons team van specialisten ontwikkelt het product volgens agile methoden. Dit doen we in{' '}
                            <span className="highlight">iteraties</span> van één of twee weken. Uit elke iteratie volgt{' '}
                            een oplevering waarmee bij testgebruikers <span className="highlight">feedback</span>{' '}
                            verzameld kan worden. We zullen{' '}
                            <span className="highlight">voort&shy;schrijdende inzichten</span> continu meenemen in{' '}
                            volgende iteraties.
                        </p>
                        <p>
                            De <span className="highlight">product&shy;eigenaar</span> bepaalt voortdurend wat{' '}
                            prioriteit heeft, verzamelt feedback en werkt gedurende het hele proces intensief samen met{' '}
                            het team. Er worden vooraf <span className="highlight">mijlpalen</span> met bijhorende{' '}
                            deadlines vastgesteld, om zo het tijdig behalen van belangrijke overkoepelende doelen te{' '}
                            waarborgen.
                        </p>
                        <p>
                            Na een aantal iteraties staat een eerste versie van het softwareproduct klaar om te{' '}
                            <span className="highlight">lanceren</span>. Hierna gaan we direct verder met{' '}
                            doorontwikkelen in hetzelfde iteratieve proces, terwijl we parallel continu de gloednieuwe{' '}
                            productieomgeving in de gaten houden.
                        </p>
                        <ul className="list">
                            <li className="ion-md-arrow-dropright">Iteraties van 1 &ndash; 2 weken</li>
                            <li className="ion-md-arrow-dropright">Tussentijdse mijlpalen</li>
                            <li className="ion-md-arrow-dropright">4 &ndash; 12 maanden</li>
                            <li className="ion-md-arrow-dropright">2 &ndash; 4 specialisten</li>
                        </ul>
                    </div>
                    <div className="content__buttons content__buttons--50">
                        <Button
                            url="/nl/werkwijze/service/"
                            name="Volgende stap: betrouwbaar serveren"
                            title="Aanpakstap 4A. Serveren"
                        />
                    </div>
                </div>
            </article>

            <article className="content">
                <div className="container">
                    <div className="content__display">
                        <h2>
                            {/* Make sure to have the correct spacing when br becomes invisible */}
                            Onze <br /> technische <br /> expertise
                        </h2>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            Softwareproducten van hoge kwaliteit creëren hangt samen met de beheersing van de{' '}
                            technologie waar je voor kiest.
                        </p>
                        <p>
                            Voor het ontwikkelen van ver&shy;nieuwende en hoog&shy;waardige ervaringen op{' '}
                            <span className="highlight">het web</span> zijn wij buiten&shy;gewoon vaardig met alle{' '}
                            onderdelen van de zogenaamde web&shy;stack. Hiermee bedoelen we bijvoorbeeld:{' '}
                            JavaScript-componenten in de front-end, MVC-architectuur in de backend, API-definities,{' '}
                            SQL-query's en cloud&shy;infrastructuur. We weten, afhankelijk van het project, waar we de{' '}
                            nadruk moeten leggen.
                        </p>
                        <p>
                            Technologie is voor ons nooit een limiterende factor. Ook als we met een techniek geen{' '}
                            ervaring hebben maken we deze onszelf snel meester. Engineers zijn dit immers gewend&mdash;{' '}
                            <span className="highlight">een leven lang leren</span> is onderdeel van het vak.
                        </p>
                    </div>
                </div>

                <div className="container">
                    <div className="logos-grid logos-grid--tech">
                        <div className="logos-grid__background" />

                        <div className="logos-grid__row">
                            <div className="logos-grid__item">
                                <Img alt="Logo React JS" fluid={data.react.childImageSharp.fluid} />
                                <span>React</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Typescript" fluid={data.typescript.childImageSharp.fluid} />
                                <span>Typescript</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Node JS" fluid={data.nodejs.childImageSharp.fluid} />
                                <span>Node.js</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Javascript" fluid={data.javascript.childImageSharp.fluid} />
                                <span>Javascript</span>
                            </div>
                        </div>

                        <div className="logos-grid__row">
                            <div className="logos-grid__item">
                                <Img alt="Logo Laravel" fluid={data.laravel.childImageSharp.fluid} />
                                <span>Laravel</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo PHP" fluid={data.php.childImageSharp.fluid} />
                                <span>PHP</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Mysql" fluid={data.mysql.childImageSharp.fluid} />
                                <span>MySQL</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Postgresql" fluid={data.postgresql.childImageSharp.fluid} />
                                <span>PostgreSQL</span>
                            </div>
                        </div>
                    </div>
                </div>
            </article>

            <Cta image={data.olav.childImageSharp.fixed} />
        </section>
    </Layout>
);

export const query = graphql`
    query {
        olav: file(relativePath: {eq: "people/olav_2_zoom.jpg"}) {
            ...inlineAvatarImage
        }

        laravel: file(relativePath: {eq: "logos/laravel.png"}) {
            ...logoImage
        }
        php: file(relativePath: {eq: "logos/php.png"}) {
            ...logoImage
        }
        mysql: file(relativePath: {eq: "logos/mysql.png"}) {
            ...logoImage
        }
        postgresql: file(relativePath: {eq: "logos/postgresql.png"}) {
            ...logoImage
        }
        javascript: file(relativePath: {eq: "logos/javascript.png"}) {
            ...logoImage
        }
        typescript: file(relativePath: {eq: "logos/typescript.png"}) {
            ...logoImage
        }
        react: file(relativePath: {eq: "logos/react.png"}) {
            ...logoImage
        }
        nodejs: file(relativePath: {eq: "logos/nodejs.png"}) {
            ...logoImage
        }
    }
`;
